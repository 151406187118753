@use 'styles/_variables.scss' as *;
.container {
  padding: $global-padding;
  max-width: 512px;
}

.info {
  font-size: 21px;
  line-height: 25px;
  margin-bottom: $global-margin * 2;
}

.notification {
  margin: $global-margin;
}

.form {
  margin-top: $global-margin;
  padding: $global-padding;
  background: $pale-gray-2;
  box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.5);
}

.fields {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: $global-margin * 2;
}

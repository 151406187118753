@use 'styles/_variables.scss' as *;
$banner-height: 40px;

.bannerHeightCompensation {
  height: $banner-height;
}

.container {
  height: $banner-height;
  background-color: #fffe53;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem 0;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;

  button {
    background-color: #f0eeef;
    color: #2f3337;
    padding: 0.25rem;
  }
}

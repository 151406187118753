@use 'styles/_variables.scss' as *;
.wrapper {
  display: none;

  @media (min-width: $l-screen) {
    display: flex;
    flex-direction: column;
    gap: 1.5 * $global-margin;
    padding: 2.75 * $global-padding 2 * $global-padding 1.5 * $global-padding 2 * $global-padding;
  }

  .item {
    display: flex !important;
    flex-direction: column;
    gap: $global-margin;
    padding: 0 !important;

    img {
      border-radius: 16px;
      border: 1px solid $pale-gray-6;
      height: 150px;
      width: 267px;
      transition: 0.3s;

      @media (max-width: $xl-screen) {
        height: 108px;
        width: 192px;
      }
    }

    .contentWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.25 * $global-margin;

      .title {
        font-family: $brand-font-family;
        font-size: 0.625 * $base-font-size;
        font-weight: 900;
        line-height: 115%;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $almost-black-60;
        text-decoration: none;
      }

      .description {
        font-family: $brand-font-family;
        font-size: 0.875 * $base-font-size;
        font-weight: 300;
        line-height: 125%;
        color: $almost-black-60;
        transition: 0.3s;
      }
    }

    &:hover {
      text-decoration: none;
      .contentWrapper {
        .title {
          color: $almost-black-60;
        }
        .description {
          color: $indigo;
          text-decoration: underline;
        }
      }
    }
  }
}

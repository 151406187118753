@use 'styles/_variables.scss' as *;
.submitButton {
  font-size: $base-font-size !important;
  padding: 16px !important;
}

.subscribe {
  @media (max-width: $l-screen) {
    max-width: 376px;
  }

  .field {
    flex-wrap: nowrap;
  }

  .fieldInput:focus {
    border-color: $cyan;
    box-shadow: 0 0 0 1px $cyan;
  }
}

@use 'styles/_variables.scss' as *;
@use "~@lulu-press/lulu-fe/dist/styles" as *;

@use "./reset.scss" as *;
@use "./animations.scss" as *;
@use "./layout.scss" as *;

@use "./fonts/fonts.scss" as *;

:root {
  --toastify-toast-width: 600px !important;
}

body {
  font-family: $brand-font-family;
}

.toast {
  .Toastify__toast-body {
    color: $almost-black-70;
    font-family: $copy-font-family;
    font-size: $base-font-size;
  }

  &-error {
    .Toastify__progress-bar--error {
      background-color: $red;
    }
  }
}

@use 'styles/_variables.scss' as *;
.searchInput {
  position: relative;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon {
  position: absolute;
  left: 0.75 * $global-margin;
  pointer-events: none;
}

.background {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: $pale-gray-3;
  border-radius: 20px;
  transition: all 200ms ease-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .alwaysOpen &,
  &:has(~ .input:focus),
  &:has(~ .input:not(:placeholder-shown)) {
    opacity: 1;
    width: 100%;
    cursor: auto;
  }

  &:has(~ .input:not(:placeholder-shown)) {
    pointer-events: none;
  }
}

.input {
  font-family: $brand-font-family;
  font-size: 0.875 * $base-font-size;
  font-weight: 500;
  line-height: 1.125 * $base-font-size;
  position: absolute;
  top: 0.625 * $global-margin;
  left: 2.5 * $global-margin;
  border: none;
  background: none;
  width: 0;

  &::placeholder {
    color: transparent;
    transition: 0.2s ease all;
  }

  .alwaysOpen &,
  &:focus:placeholder-shown {
    width: calc(100% - 52px);
    &::placeholder {
      color: #a7adb2;
    }
  }

  &:focus,
  &:not(:placeholder-shown) {
    outline: none;
    width: calc(100% - 118px);
  }
}

.reset {
  font-family: $brand-font-family;
  font-size: 0.875 * $base-font-size;
  font-weight: 500;
  line-height: 1.125 * $base-font-size;
  opacity: 0;
  color: #a7adb2;
  padding-right: 1.2rem;
  transition: opacity 200ms ease-out;
  pointer-events: none;
  z-index: 1;

  &:has(~ .input:not(:placeholder-shown)) {
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}

@font-face {
  font-family: "museo-sans";
  src: url("./MuseoSans/MuseoSans_300.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src: url("./MuseoSans/MuseoSans_300_Italic.woff2") format("woff2");
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src: url("./MuseoSans/MuseoSans_500.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src: url("./MuseoSans/MuseoSans_500_Italic.woff2") format("woff2");
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src: url("./MuseoSans/MuseoSans_700.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src: url("./MuseoSans/MuseoSans_700_Italic.woff2") format("woff2");
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src: url("./MuseoSans/MuseoSans_900.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src: url("./MuseoSans/MuseoSans_900_Italic.woff2") format("woff2");
  font-display: swap;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: "interface";
  src: url("./InterFace/InterFace_Rg.ttf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "interface";
  src: url("./InterFace/InterFace_Rg.ttf") format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "interface";
  src: url("./InterFace/InterFace_Bd.ttf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "interface";
  src: url("./InterFace/InterFace_Bd.ttf") format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

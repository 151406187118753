@use 'styles/_variables.scss' as *;
.searchBar {
  background-color: $indigo;
  color: $white;
  display: none;
  position: relative;

  p {
    font-family: $brand-font-family;
  }

  &Active {
    display: block;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 942px;
    margin: 0 auto;

    @media (max-width: $m-big-screen) {
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
    }
  }
}

.inputWrapper {
  max-width: 42rem;

  @media (max-width: $m-big-screen) {
    max-width: unset;
  }
}

.toggleCategories {
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    font-size: $base-font-size;
    margin: 0;
    margin-left: 1.5rem;
  }

  i {
    font-size: 2 * $base-font-size;
    margin-left: 0.5rem;
  }

  @media (max-width: $m-big-screen) {
    margin: -3px 0 $global-margin * 0.8;
  }
}

.categoryDropdownWrapper {
  border-bottom: 4px solid $indigo;

  .categoryDropdown {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 342px;
    padding-top: $global-padding;
    padding-bottom: $global-padding;
    font-size: $base-font-size;
    overflow-y: hidden;
    overflow-x: auto;

    @media (max-width: $l-screen) {
      max-height: 420px;
    }

    @media (max-width: $m-big-screen) {
      max-height: 620px;
    }

    @media (max-width: $m-screen) {
      max-height: unset;
    }

    > a {
      margin: ($global-margin * 0.5) ($global-margin * 2) ($global-margin * 0.5) 0;
    }
  }
}

@use 'styles/_variables.scss' as *;
.wrapper {
  display: grid;
  grid-template-columns: 1fr minmax(1px, 1440px) 1fr;
  background-color: $pale-gray-2;

  .content {
    display: flex;

    @media (max-width: $m-big-screen) {
      flex-direction: column;
    }
  }

  .accountNavigationWrapper {
    padding: 0 2.5 * $global-padding;
    margin-left: auto;
    font-family: $copy-font-family;

    @media (max-width: $m-big-screen) {
      padding: 0 $global-padding;
    }
  }
}

.announcement {
  display: flex;
  align-items: center;
  padding: $global-padding * 0.5 $global-padding * 2.5;

  @media (max-width: $l-screen) {
    font-size: $small-font-size;
  }

  .linkIcon {
    margin-right: $global-margin * 0.5;
  }

  .text {
    margin: 0;
    padding: 0;
  }
}

.accountNavigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
}

.menuItem {
  list-style: none;
  position: relative;
  margin: 0;
  padding: $global-padding * 0.5 $global-padding * 0.25;
  padding-right: $global-padding;

  &:last-child {
    padding-right: 0;
  }

  &:hover {
    .dropdownMenu {
      display: block;
    }
  }

  .title {
    color: $almost-black-60;
    cursor: pointer;
    display: flex;
    font-family: $brand-font-family;
    font-size: $small-font-size;

    @media (min-width: $l-screen) {
      font-size: $base-font-size;
    }

    i {
      vertical-align: text-top;
      display: inline-block;
    }

    &:hover {
      text-decoration: none;
      color: $indigo;

      i {
        text-decoration: none;
      }
    }
  }

  .dropdownMenu {
    display: none;
    position: absolute;
    top: 2.1rem;
    right: 0;
    min-width: 150px;
    width: max-content;
    background-color: $white;
    border: 1px solid $pale-gray-5;
    border-radius: $button-border-radius;
    box-shadow: $button-dropdown-shadow;
    line-height: 1.5;
    padding: $global-padding 0;
    z-index: 10;
    font-family: $brand-font-family;

    @media (max-width: $xl-screen) {
      top: 1.8rem;
    }

    &:after,
    &:before {
      bottom: 100%;
      left: 90%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-bottom-color: $white;
      border-width: 8px;
      margin-left: -8px;
    }

    &:before {
      border-bottom-color: $pale-gray-5;
      border-width: 9px;
      margin-left: -9px;
    }

    a,
    span {
      display: block;
      cursor: pointer;
    }

    a {
      font-weight: 700;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    small {
      color: $almost-black-60;
      font-size: 0.625 * $base-font-size;
      font-family: $brand-font-family;
    }

    .fullName {
      font-size: $small-font-size;
      font-weight: 500;
    }

    span {
      color: $almost-black-60;
    }

    div {
      padding-inline: $global-padding;

      &:first-child {
        border-bottom: 1px solid $pale-gray-4;
        padding-bottom: 0.5 * $global-padding;
      }

      &:last-child {
        padding-top: 0.5 * $global-padding;
      }
    }
  }
}

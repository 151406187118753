.spinner {
  animation: progressAnimation 1.2s linear infinite;
}

@keyframes progressAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

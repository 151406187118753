@use 'styles/_variables.scss' as *;
.header {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: $white;

  &:has(.menuItemsActive) {
    position: fixed;
    top: 0;
  }
}

.navigation {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: $global-border * 0.5 solid $pale-gray-2;
  height: 48px;

  @media (min-width: $l-screen) {
    height: 56px;
  }

  .left,
  .right {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin: 0;
    align-items: center;
  }

  .left {
    .navigationItem {
      display: inline-block;
      vertical-align: middle;

      .logo {
        display: block;
        padding: 0.25 * $global-padding;

        @media (min-width: $l-screen) {
          padding: 0.5 * $global-padding 1.5 * $global-padding 0.75 * $global-padding 0.25 * $global-padding;
        }

        @media (min-width: $xl-screen) {
          padding: 0.5 * $global-padding 1.75 * $global-padding 0.75 * $global-padding 0.25 * $global-padding;
        }

        img {
          height: 1.5625 * $base-font-size;

          @media (min-width: $l-screen) {
            height: $base-font-size * 1.9375;
          }
        }
      }
    }

    .mobileMenuBackdrop {
      display: none;

      &Active {
        display: block;
        background: rgba(0, 0, 0, 0.2);
        position: fixed;
        bottom: 0;
        right: 0;
        height: calc(100dvh - var(--header-height, 81px));
        right: 0;
        width: 20vw;
        z-index: 9;
      }
    }

    .menuItems {
      padding: 0;
      margin: 0;
      display: flex;
      z-index: 10;
      align-items: center;
      grid-template-columns: repeat(auto-fill, 10rem);
      height: 100%;
      position: relative;

      * {
        // remove blue highlighting on chrome (on click)
        -webkit-tap-highlight-color: transparent;
      }

      @media (max-width: calc($l-screen + 1px)) {
        height: initial;
        display: none;
        background-color: $white;

        &Active {
          position: fixed;
          bottom: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0;
          width: 80vw;
          height: calc(100dvh - var(--header-height, 81px));
          overflow: auto;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

          @media (min-width: $m-screen) {
            box-shadow: -4px 4px 8px 0px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  .right {
    justify-content: space-evenly;
    align-items: center;

    li:last-of-type {
      a {
        margin-right: 0;
      }
    }

    li:first-of-type {
      a {
        margin-left: 0;
      }
    }

    .itemIndicator {
      position: absolute;
      font-size: $extra-small-font-size;
      font-weight: bold;
      color: $indigo;
      border: 0.125rem solid $indigo;
      background-color: $white;
      border-radius: 100%;
      top: -5px;
      left: 1rem;
      min-width: 21px;
      height: 21px;
      text-align: center;
      line-height: 12px;
      padding: $global-padding * 0.125;

      &Loading {
        border-top-width: 0;
        border-left-width: 0;
      }
    }

    .accountMenuItem {
      &.menuItem {
        padding: 0.75 * $global-padding 0;

        @media (max-width: calc($l-screen + 1px)) {
          padding: 0.5 * $global-padding 0;
        }

        &:hover {
          & > span {
            background-color: $pale-gray-2;
          }

          .dropdownMenu {
            right: -2rem;
            top: 90%;
            min-width: max-content;

            &:after,
            &:before {
              left: calc(100% - 2.7rem);
            }

            &UserLogged {
              display: table;
            }

            span[data-testid="main-nav-login"],
            span[data-testid="main-nav-register"] {
              font-weight: 700;
            }
          }
        }

        .dropdownMenuUserLogged {
          width: max-content;

          @media (max-width: calc($l-screen + 1px)) {
            width: 100%;
          }

          .label {
            color: $indigo;
            cursor: default;

            .email {
              font-weight: 500;
              cursor: default;
            }

            &:hover,
            .email:hover {
              text-decoration: none;
            }

            small {
              color: $almost-black-60;
              padding-bottom: 4px;
              font-size: 0.625 * $base-font-size;
              font-family: $brand-font-family;
            }

            span,
            a {
              padding-left: 0;
              padding-right: 0;
              font-size: $small-font-size;
            }
          }

          & > span {
            font-size: $base-font-size;
            line-height: 150%;
            font-weight: 700;
          }
        }
      }

      @media (max-width: calc($l-screen + 1px)) {
        &.menuItem {
          position: unset;
        }

        .accountBar {
          display: none !important;

          &Active {
            display: block !important;
            position: absolute;
            width: 100%;
            left: 0;
            top: 100%;
            border-radius: unset;
            box-shadow: none;
            border: none;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
            border-top: $global-border * 0.5 solid $pale-gray-border;
            color: $indigo;

            span {
              font-size: $base-font-size;
              font-weight: 700;

              &[data-testid="main-nav-login"] {
                margin-bottom: 0.25 * $global-margin;
              }

              &[data-testid="main-nav-order-lookup"] {
                font-weight: 500;
              }
            }
          }

          &:before,
          &:after {
            left: calc(100% - 5rem) !important;

            @media (max-width: $m-screen) {
              left: calc(100% - 4.5rem) !important;
            }

            @media (max-width: $s-screen) {
              left: calc(100% - 3.7rem) !important;
            }
          }
        }
      }
    }
  }

  .navigationItem {
    display: inline-block;
    vertical-align: middle;

    &Icon {
      display: flex;
      position: relative;
      border: $global-border solid transparent;
      border-inline-width: 2 * $global-border;
      border-radius: $global-border;
      margin-left: $global-margin * 0.8;

      &Active {
        position: relative;
        background: $pale-gray-2;
        border-color: $pale-gray-2;

        &#searchToggle {
          &:after,
          &:before {
            top: 2.4rem;
            left: 50%;
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            z-index: 2;

            @media (max-width: $m-big-screen) {
              top: 2rem;
            }
          }

          &:after {
            border-top-color: $white;
            border-width: 12px;
            margin-left: -12px;
          }

          &:before {
            border-top-color: $white;
            border-width: 12px;
            margin-left: -12px;
          }
        }

        &:not(.searchActive)#cart {
          &:after {
            content: "";
            height: 10px;
            width: 10px;
            display: block;
            position: absolute;
            transform: rotate(45deg);
            background-color: $white;
            border-right: $global-border * 0.5 solid $pale-gray-2;
            border-bottom: $global-border * 0.5 solid $pale-gray-2;
            bottom: -1.3em;
            right: 0.5em;

            @media (max-width: calc($l-screen + 1px)) {
              bottom: -1.8em;
            }

            @media (max-width: $m-big-screen) {
              bottom: -1.4em;
            }
          }
        }
      }

      &:hover {
        cursor: pointer;
        text-decoration: none;
        background-color: $pale-gray-2;
        border-color: $pale-gray-2;

        .itemIndicator {
          background-color: $pale-gray-3;
        }
      }

      svg {
        display: block;
        width: $h2-font-size;

        * {
          fill: $indigo;
        }
      }
    }
  }

  @media (max-width: calc($l-screen + 1px)) {
    font-size: $small-font-size;
  }

  i {
    font-size: 1.375rem;
    color: $indigo;
  }
}

.breakLine {
  height: 1px;
  background-color: $pale-gray-4;
  margin-top: $global-margin * 0.5;
  margin-bottom: $global-margin * 0.5;
}

.mobileMenu {
  display: none;

  @media (max-width: calc($l-screen + 1px)) {
    display: block;
  }
}

.megaMenuNavigation {
  height: 51px;

  @media (min-width: $l-screen) {
    height: 64px;
  }
}

.megaMenuRight {
  gap: $global-margin;

  @media (max-width: calc($l-screen + 1px)) {
    gap: 0.5 * $global-margin;
  }
}

.flyout {
  width: 190px !important;
  border-color: $pale-gray-4 !important;

  .userDetails {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: $global-padding 1.5 * $global-padding;
    border-bottom: 1px solid $pale-gray-4;

    .userName {
      font-size: $base-font-size;
      font-weight: 700;
      line-height: 150%;
    }

    .email {
      color: $almost-black-60;
      font-size: 0.875 * $base-font-size;
      font-weight: 500;
      line-height: 125%;
      -webkit-box-orient: vertical;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .links {
    border-bottom: 1px solid $pale-gray-4;

    & > a {
      display: block;
      padding: 0.75 * $global-padding 1.5 * $global-padding;
      color: $almost-black;
      font-size: 0.875 * $base-font-size;
      line-height: 125%;
      text-decoration: none;

      &:hover {
        background-color: $pale-gray-2;
      }

      &:active {
        background-color: $pale-gray-4;
      }
    }

    .button {
      display: block;
      margin: $global-padding 1.5 * $global-padding;
      width: 142px;

      &.loginButton {
        background: $pale-gray-2;
      }
    }
  }

  .logout {
    padding: $global-padding 1.5 * $global-padding;
    display: flex;
    align-items: center;
    gap: 0.5 * $global-margin;
    cursor: pointer;
    font-size: 0.875 * $base-font-size;

    &:hover {
      background-color: $pale-gray-2;
    }

    &:active {
      background-color: $pale-gray-4;
    }

    svg > g {
      fill: $almost-black !important;
    }
  }
}

.cartButtonLink {
  display: block;
}

.megaMenuNavItemIcon {
  margin: 0.375 * $global-margin;

  svg {
    width: 28px;
    height: 28px;
  }

  &:hover,
  &.active {
    background-color: $pale-gray-2 !important;
  }

  &:active {
    background-color: $pale-gray-4 !important;
  }

  &.amberIconButton {
    margin: 0;
    border-width: 0;
  }

  &.activeSearch {
    background-color: $pale-gray-2;
  }
}

.megaMenuCartWithBadge {
  background-color: $pale-gray-2 !important;

  & > span {
    top: -3px !important;
    right: -3px !important;
  }
}

.megaMenuAccountButton {
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $base-font-size;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: $white;
  background-color: $dark-gray-5;
  margin: 0.125 * $global-margin;
  border: 2px solid $dark-gray-1;
  user-select: none;
  cursor: pointer;
  line-height: 32px;
}

.megaMenuNavbarButton {
  position: relative;
  display: flex;
  justify-content: center;
  width: 40px;
  height: 28px;
  margin-left: 0 !important;
  border: none !important;

  &:hover,
  &:active {
    background: transparent !important;
  }

  & > svg {
    transition: 0.3s;
    position: absolute;
    opacity: 0;
    width: 28px !important;

    path {
      fill: $dark-gray-4 !important;
    }
  }

  .iconVisible {
    opacity: 1;
  }

  .scaleUp {
    transform: scale(1);
  }

  .scaleDown {
    transform: scale(0.54);
  }
}

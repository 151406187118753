@use 'styles/_variables.scss' as *;
.searchSectionWrapper {
  position: relative;
  border-bottom: 1px solid $pale-gray-4;
  background-color: $pale-gray-1;

  .searchContainer {
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    box-sizing: content-box;

    @media (max-width: $m-big-screen) {
      padding-inline: 0;
    }

    @media (min-width: ($m-screen + 1px)) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .inputWrapper {
      display: block;
      flex-grow: 1;
      padding: $global-padding;
    }

    .toggleCategories {
      display: flex;
      align-items: center;
      align-self: flex-end;
      justify-content: space-between;
      cursor: pointer;
      padding: 0 $global-padding $global-padding;

      @media (min-width: ($m-screen + 1px)) {
        padding: $global-padding;
      }
      p {
        font-family: $brand-font-family;
        font-size: 0.875 * $base-font-size;
        font-weight: 500;
        line-height: 125%;
        width: max-content;
        font-weight: 500;
        color: $dark-gray-3;

        @media (max-width: $m-screen) {
          margin: 0;
        }
      }
      & > i {
        width: 24px;
        height: 24px;
        font-size: 24px;
        color: $dark-gray-3;
        transition: 0.2s;

        &.rotate {
          transform: scaleY(-1);
        }
      }
    }
  }
}

.categoryDropdownWrapper {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  background-color: $white;
  max-height: calc(calc(100dvh - var(--header-height)) - var(--search-section-height));
  overflow-y: auto;

  .maxWidthContainer {
    padding-top: 0.5 * $global-padding;
    padding-bottom: 1.5 * $global-padding;
    max-width: 1100px;
    box-sizing: content-box;

    @media (max-width: $m-screen) {
      padding-top: 0;
      padding-bottom: 0;
    }

    .categoryDropdown {
      padding: $global-padding 0;
      display: grid;
      grid-auto-flow: column;
      grid-template: repeat(9, minmax(min-content, max-content)) / repeat(4, 1fr);
      column-gap: 2 * $global-margin;
      row-gap: 0.5 * $global-margin;

      @media (max-width: 900px) {
        column-gap: 0.75 * $global-margin;
      }

      @media (max-width: $m-big-screen) {
        grid-template: repeat(11, minmax(min-content, max-content)) / repeat(3, 1fr);
      }

      @media (max-width: $m-screen) {
        column-gap: 0;
        grid-template: repeat(17, minmax(min-content, max-content)) / repeat(2, 1fr);
      }

      a {
        padding: 0.75 * $global-padding $global-padding;
        color: $almost-black;
        line-height: 125%;
        font-size: 0.875 * $base-font-size;
        text-wrap: pretty;
      }
    }
  }
}

@use 'styles/_variables.scss' as *;
.wrapper {
  display: grid;
  grid-template-columns: 1fr minmax(1px, 1440px) 1fr;
  background-color: $pale-gray-2;

  .content {
    display: flex;

    @media (max-width: $m-big-screen) {
      flex-direction: column;
    }
  }

  .accountNavigationWrapper {
    padding: 0 2.5 * $global-padding;
    margin-left: auto;
    font-family: $copy-font-family;

    @media (max-width: $m-big-screen) {
      padding: 0 $global-padding;
    }
  }
}

.announcement {
  display: flex;
  align-items: center;
  padding: $global-padding * 0.5 $global-padding * 2.5;

  @media (max-width: $l-screen) {
    font-size: $small-font-size;
  }

  .linkIcon {
    margin-right: $global-margin * 0.5;
  }

  .text {
    margin: 0;
    padding: 0;
  }
}

.accountNavigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
}

.menuItem {
  list-style: none;
  position: relative;
  margin: 0;
  padding: $global-padding * 0.5 $global-padding * 0.25;
  padding-right: $global-padding;

  &:last-child {
    padding-right: 0;
  }

  .title {
    color: $almost-black-60;
    cursor: pointer;
    display: flex;
    font-family: $brand-font-family;
    font-size: $small-font-size;

    @media (min-width: $l-screen) {
      font-size: $base-font-size;
    }

    i {
      vertical-align: text-top;
      display: inline-block;
    }

    &:hover {
      text-decoration: none;
      color: $indigo;

      i {
        text-decoration: none;
      }
    }
  }
}

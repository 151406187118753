@use "~@lulu-press/lulu-fe/dist/styles/variables" as *;

.page-container {
  margin: 0 auto;
  max-width: $l-screen;
  width: 100%;
  box-sizing: border-box;
  padding: 0 $global-padding * 2 $global-padding * 5 $global-padding * 2;

  @media (max-width: $s-screen) {
    padding: 0 $global-padding $global-padding * 3 $global-padding;
  }

  @media (max-width: $xs-screen) {
    margin: 0;
  }
}

.box {
  background-color: $white;
  border-radius: $button-border-radius;
  padding: $global-padding * 3 $global-padding * 2.5;
  margin: $global-margin * 2.5 0;

  &__section {
    margin-top: $global-margin;
  }

  @media (max-width: $m-big-screen) {
    padding: $global-padding * 2.5 $global-padding * 2;
  }

  @media (max-width: $m-screen) {
    padding: $global-padding * 2 $global-padding * 1.5;
  }

  @media (max-width: $s-screen) {
    padding: $global-padding * 1.5 $global-padding;
  }
}

.max-width-container {
  max-width: 1440px;
}

.full-height-container {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

.fill-vertical-space {
  display: flex;
  flex: 2;
  flex-direction: column;
}

@use 'styles/_variables.scss' as *;
.navigation {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: $global-border * 0.5 solid $pale-gray-2;
  height: 48px;

  @media (min-width: $m-big-screen) {
    height: 56px;
  }

  .left,
  .right {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin: 0;
    align-items: center;
  }

  .left {
    .navigationItem {
      display: inline-block;
      vertical-align: middle;

      .logo {
        margin: 0 2 * $global-margin;
        margin-left: 0;
        position: relative;
        vertical-align: middle;
        padding: 0.5 * $global-padding 0;

        img {
          height: 2.5 * $base-font-size;

          @media (max-width: $l-screen) {
            height: $base-font-size * 2;
          }
        }
      }
    }

    .menuItems {
      padding: 0;
      margin: 0;
      display: flex;
      z-index: 2;
      align-items: flex-end;
      grid-template-columns: repeat(auto-fill, 10rem);

      @media (max-width: $m-big-screen) {
        display: none;
        position: absolute;
        background-color: $white;
        top: 2.8rem;
        left: 0;
        right: 0;
        padding: $global-padding;
        margin-top: -1px;
        border-top: $global-border * 0.5 solid $pale-gray-2;

        &Active {
          display: flex;
          flex-direction: column;
          width: 100%;
          top: 3rem;
          right: 0;
          left: auto;
          align-items: flex-start;
          gap: $global-margin;
          padding: 2 * $global-padding 1.5 * $global-padding;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

          @media (min-width: $m-screen) {
            max-width: 329px;
            min-height: calc(calc(100vh - 53px) - var(--top-bar-height, 0px));
            box-shadow: -4px 4px 8px 0px rgba(0, 0, 0, 0.1);
            gap: 0.5 * $global-margin;
            padding: 2 * $global-padding;
          }

          i {
            font-size: $h4-large-font-size !important;
          }
        }

        .menuItem {
          display: block;
          width: 100%;

          .title {
            padding: 0;
            margin: 0;
            font-size: $base-font-size;
            display: flex;
            justify-content: space-between;

            &:active {
              border: none;
            }

            &:hover .sectionIcon:before {
              background: $pale-gray-3;
              border-radius: $global-border-radius;
            }

            &:hover > span {
              text-decoration: underline;
              border: none;
            }
          }

          .sectionIcon {
            transform: rotate(-90deg);
            transition: 0.1s;

            &.active {
              transform: rotate(0);

              &::before {
                background: $pale-gray-3;
                border-radius: $global-border-radius;
              }
            }
          }

          .dropdownMenu {
            display: none;
            position: relative;
            top: 0;
            padding-top: 0;
            border: none;
            box-shadow: none;
            padding-left: $global-padding * 0.5;

            &.active {
              display: block;
              padding: 0;
              padding-top: 0.5 * $global-padding;

              & > * {
                padding: 0.5 * $global-padding $global-padding;
                border-bottom: 1px solid $pale-gray-4;
                z-index: 2;

                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .right {
    justify-content: space-evenly;
    align-items: center;

    li:last-of-type {
      a {
        margin-right: 0;
      }
    }

    li:first-of-type {
      a {
        margin-left: 0;
      }
    }

    .itemIndicator {
      position: absolute;
      font-size: $extra-small-font-size;
      font-weight: bold;
      color: $indigo;
      border: 0.125rem solid $indigo;
      background-color: $white;
      border-radius: 100%;
      top: -5px;
      left: 1rem;
      min-width: 21px;
      height: 21px;
      text-align: center;
      line-height: 12px;
      padding: $global-padding * 0.125;

      &Loading {
        border-top-width: 0;
        border-left-width: 0;
      }
    }

    .accountMenuItem {
      &.menuItem {
        padding: 0.75 * $global-padding 0;

        @media (max-width: $m-big-screen) {
          padding: 0.5 * $global-padding 0;
        }

        &:hover {
          & > span {
            background-color: $pale-gray-2;
          }

          .dropdownMenu {
            right: -2rem;
            top: 90%;
            min-width: max-content;

            &:after,
            &:before {
              left: calc(100% - 2.7rem);
            }

            &UserLogged {
              display: table;
            }

            span[data-testid="main-nav-login"],
            span[data-testid="main-nav-register"] {
              font-weight: 700;
            }
          }
        }

        .dropdownMenuUserLogged {
          width: max-content;

          @media (max-width: $m-big-screen) {
            width: 100%;
          }

          .label {
            color: $indigo;
            cursor: default;

            .email {
              font-weight: 500;
              cursor: default;
            }

            &:hover,
            .email:hover {
              text-decoration: none;
            }

            small {
              color: $almost-black-60;
              padding-bottom: 4px;
              font-size: 0.625 * $base-font-size;
              font-family: $brand-font-family;
            }

            span,
            a {
              padding-left: 0;
              padding-right: 0;
              font-size: $small-font-size;
            }
          }

          & > span {
            font-size: $base-font-size;
            line-height: 150%;
            font-weight: 700;
          }
        }
      }

      @media (max-width: $m-big-screen) {
        &.menuItem {
          position: unset;
        }

        .accountBar {
          display: none !important;

          &Active {
            display: block !important;
            position: absolute;
            width: 100%;
            left: 0;
            top: 100%;
            border-radius: unset;
            box-shadow: none;
            border: none;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
            border-top: $global-border * 0.5 solid $pale-gray-border;
            color: $indigo;

            span {
              font-size: $base-font-size;
              font-weight: 700;

              &[data-testid="main-nav-login"] {
                margin-bottom: 0.25 * $global-margin;
              }

              &[data-testid="main-nav-order-lookup"] {
                font-weight: 500;
              }
            }
          }

          &:before,
          &:after {
            left: calc(100% - 5rem) !important;

            @media (max-width: $m-screen) {
              left: calc(100% - 4.5rem) !important;
            }

            @media (max-width: $s-screen) {
              left: calc(100% - 3.7rem) !important;
            }
          }
        }
      }
    }
  }

  .navigationItem {
    display: inline-block;
    vertical-align: middle;

    &Icon {
      display: flex;
      position: relative;
      border: $global-border solid transparent;
      border-inline-width: 2 * $global-border;
      border-radius: $global-border;
      margin-left: $global-margin * 0.8;

      &Active {
        position: relative;
        background: $pale-gray-2;
        border-color: $pale-gray-2;

        &#searchToggle {
          &:after,
          &:before {
            top: 2.4rem;
            left: 50%;
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            z-index: 2;

            @media (max-width: $m-big-screen) {
              top: 2rem;
            }
          }

          &:after {
            border-top-color: $white;
            border-width: 12px;
            margin-left: -12px;
          }

          &:before {
            border-top-color: $white;
            border-width: 12px;
            margin-left: -12px;
          }
        }

        &:not(.searchActive)#cart {
          &:after {
            content: "";
            height: 10px;
            width: 10px;
            display: block;
            position: absolute;
            transform: rotate(45deg);
            background-color: $white;
            border-right: $global-border * 0.5 solid $pale-gray-2;
            border-bottom: $global-border * 0.5 solid $pale-gray-2;
            bottom: -1.3em;
            right: 0.5em;

            @media (max-width: $l-screen) {
              bottom: -1.8em;
            }

            @media (max-width: $m-big-screen) {
              bottom: -1.4em;
            }
          }
        }
      }

      &:hover {
        cursor: pointer;
        text-decoration: none;
        background-color: $pale-gray-2;
        border-color: $pale-gray-2;

        .itemIndicator {
          background-color: $pale-gray-3;
        }
      }

      svg {
        display: block;
        width: $h2-font-size;

        * {
          fill: $indigo;
        }
      }
    }
  }

  .menuItem {
    position: relative;
    display: inline-block;

    &:hover .dropdownMenu {
      display: flex;
      flex-direction: column;
      gap: 0.25 * $global-margin;
    }

    @media (max-width: $l-screen) {
      font-size: $small-font-size;
    }

    &:hover,
    &.active .title {
      text-decoration: none;
      color: $indigo;

      i {
        text-decoration: none;
      }
    }

    .title {
      color: $almost-black-60;
      cursor: pointer;
      display: flex;

      i {
        vertical-align: text-top;
        display: inline-block;
      }
    }

    .dropdownMenu {
      display: none;
      position: absolute;
      top: 2 * $global-margin;
      min-width: 150px;
      background-color: $white;
      border: 1px solid $pale-gray-5;
      border-radius: $button-border-radius;
      box-shadow: $button-dropdown-shadow;
      line-height: 1.5;
      padding: $global-padding 0;
      z-index: 4;

      &AccountNavigation {
        top: 2.1rem;
        left: -3rem;

        @media (max-width: $l-screen) {
          top: 1.8rem;
          left: -4rem;
        }

        @media (max-width: $s-screen) {
          left: -4.5rem;
        }
      }

      @media (min-width: calc($m-big-screen + 1px)) {
        &:after,
        &:before {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-bottom-color: $white;
          border-width: 8px;
          margin-left: -8px;
        }

        &:before {
          border-bottom-color: $pale-gray-5;
          border-width: 9px;
          margin-left: -9px;
        }
      }

      a,
      div,
      span {
        display: block;
        padding: 0 1rem;
        cursor: pointer;
      }

      span:hover {
        text-decoration: underline;
      }

      hr {
        border: 0;
        border-top: 1px solid $pale-gray-5;
      }
    }

    &.withoutDropdown .title {
      @media (min-width: $m-big-screen) {
        padding-bottom: 0.5 * $global-padding;
        border-bottom: 0.25 * $global-margin solid transparent;
        transition: 0.15s;

        &:hover {
          border-bottom: 0.25 * $global-margin solid $indigo;
        }
      }
    }

    &Main .title {
      font-family: $brand-font-family;
      font-weight: bold;
      font-size: $small-font-size;
      color: $indigo;
      margin: $global-margin;
      margin-bottom: 0;
      display: flex;
      padding-bottom: 0.75 * $global-padding;
      line-height: $base-font-size * 1.5;

      &:hover,
      &.active {
        text-decoration: none;
      }

      @media (max-width: $l-screen) {
        margin: $global-margin $global-margin * 0.75 0;
      }

      @media (min-width: $l-screen) {
        font-size: $base-font-size;
      }

      i {
        font-size: $base-font-size * 1.5;
      }
    }

    &Main .dropdownMenu {
      top: 3.25 * $global-margin;
      left: 50%;
      transform: translateX(-50%);
      font-size: $base-font-size;

      @media (min-width: $m-big-screen) and (max-width: $l-screen) {
        font-size: $small-font-size;
      }

      a {
        white-space: nowrap;
      }

      &.active {
        display: block;
      }
    }
  }

  @media (max-width: $l-screen) {
    font-size: $small-font-size;
  }

  i {
    font-size: 1.375rem;
    color: $indigo;
  }
}

.breakLine {
  height: 1px;
  background-color: $pale-gray-4;
  margin-top: $global-margin * 0.5;
  margin-bottom: $global-margin * 0.5;
}

.mobileMenu {
  display: none;

  @media (max-width: $m-big-screen) {
    display: block;
  }
}
